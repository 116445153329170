.App-navbar-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  margin-top: 40px;
}

.App-navbar-name h1 {
    font-size: 20px;
    padding-left: 40px;
}

.App-navbar-links {
  display: flex;
  align-items: center;
  margin-right: 60px;
  gap: 20px;
}

.navbar-link {
  font-size: 20px;
  text-decoration: none;
  color: inherit;
}

.navbar-link.active {
  text-decoration: underline; /* Underlines the active tab */
}

.App-navbar-links img {
  width: 25px;
  height: 25px;
}
