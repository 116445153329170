.App-contact {
  width: 100%;
  height: 70vh;
  margin: 0;
  padding-left: 0;
}

.App-contact-title h1 {
  font-size: 40px;
  margin-top: 70px;

  margin-left: 30px;

}

.App-contact-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create 3 equally sized columns */
  grid-template-rows: auto auto; /* Two rows: one for h2, one for p */
  gap: 20px; /* Space between columns */
  text-align: left; /* Center align text in each cell */
  margin-top: 100px; /* Push the grid down by 50px */
  margin-left: 30px;
}

.App-contact-details h2 {
  grid-row: 1; /* Place all h2 elements in the first row */
  margin: 0;
  font-size: 20px;
}

.App-contact-details p {
  grid-row: 2; /* Place all p elements in the second row */
  margin: 0;
  font-size: 20px;
  border: none;
  outline: none; /* Remove any outlines */
  animation: none; /* Ensure no animation is applied */
}

