.App-projects {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 70vh;
  margin: 0;
  padding: 20px;
  position: relative;
}

.App-projects-title {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 10;
}

.App-projects-title h1 {
  font-size: 40px;
  text-align: left;
  margin-bottom: 20px;
}

.App-projects-details {
  display: grid;
  grid-template-columns: 2fr 1fr; 
  grid-template-rows: 1fr 1fr; 
  gap: 10px; 
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 80px;
  margin-top: 100px;
  max-width: 70%; 
  max-height: 70%; 
}

.App-projects-large-rect,
.App-projects-small-rect {
  position: relative;
  background-color: #f5f5f5;
  border: 2px solid black;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
}

.App-projects-large-rect {
  grid-column: 1 / 2; 
  grid-row: 1 / 3;
}

.App-projects-small-rect {
  grid-column: 2 / 3; 
}

.App-projects-large-rect img,
.App-projects-small-rect img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.App-projects-large-rect img {
  object-position: right;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  opacity: 0;
  transition: opacity 0.3s ease;
  animation: none;
}

.project-overlay p {
  font-size: 35px;
  text-align: center;
  animation: none;
  border: none;
  font-style: bold;
}

.App-projects-large-rect:hover img,
.App-projects-small-rect:hover img {
  opacity: 0.1;
}

.App-projects-large-rect:hover .project-overlay,
.App-projects-small-rect:hover .project-overlay {
  opacity: 1;
}

