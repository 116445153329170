.App-main-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    align-items: flex-start; 
    height: 70vh; 
    text-align: left;
    padding-bottom: 60px; 
    padding-left: 20px;
}

.App-main-page h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 68px; 
    margin: 0;
    white-space: nowrap; /* Prevents the text from breaking into new lines */
}

/* Apply typing effect only to p */
.App-main-page p {
    font-family: 'Raleway', sans-serif;
    font-size: 68px; 
    margin: 0;
    overflow: hidden; /* Hide the content while it types */
    white-space: nowrap; /* Prevents the text from breaking into new lines */
    border-right: 4px solid black; /* Adds the cursor effect */
    padding-right: 5px; /* Adds space after the text */
    animation: typing 2s steps(60) 1s forwards, blink 0.75s step-end infinite;
    animation-delay: 0s, 30s; /* Remove blinking after 30 seconds */
}

/* Keyframe for typing effect */
@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

/* Keyframe for blinking cursor effect */
@keyframes blink {
    50% {
        border-color: transparent;
    }
}

/* Make the span italic */
span {
    font-style: italic; /* Make the word "portfolio" italic */
}
