body {
  background-color: #d8cabf;
}

.Navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333; /* You can adjust this */
  color: white;
  padding: 10px;
  z-index: 10; 
}

.App-main-page {
  padding-top: 80px; 
}



